export const apiPublicUrls = {
  onlineTestV2: {
    GET_RESOURCE_STATUS: '/resource/:id/:path',
    // GET_STATISTICS_BY_BOOK_ID: '/statistics/do-test?type=BOOK&referenceId=:id',
    GET_PAPER_SCHEDULE: '/special-object/ielts-schedule/paper-test',
    GET_SPECIAL_OBJECT_IELTS_SCHEDULE_COMPUTER_TEST: '/special-object/ielts-schedule/computer-test',
    GET_SPECIAL_OBJECT_IELTS_PURPOSE: '/special-object/ielts-purpose',
    DAILY_LEARNING_NEWS_DETAIL: '/daily-learning/news/landing/:id',
    GET_ACTIVE_STORIES: '/daily-learning/stories/active',
    GET_ACTIVE_RIDDLE: '/daily-learning/riddles/active',
    GET_ACTIVE_FACT: '/daily-learning/facts/active',
    GET_ACTIVE_VOCAB: '/daily-learning/vocabs/active',
    EXERCISES_PROGRESS: '/exercises/progress',
    DAILY_LEARNING_RIDDLE_PROGRESS: '/daily-learning/riddles/:id/progress',
    GET_TOPICS: '/topics',
  },
  pageManagement: {
    GET_BLOGS: '/blogs',
    GET_PRACTICE_TESTS: '/practice-test/overview',
    GET_TEST_INFORMATION_DETAIL: '/page/tests/:testType/:testId',
    GET_DICTATIONS_OVERVIEW: '/dictations/overview',
    GET_VOCAB_GRAMMAR_BLOGS: '/ielts-blogs/vocab-grammar',
    GET_VOCAB_GRAMMAR_BLOGS_LANDING: '/ielts-blogs/vocab-grammar/landing',
    GET_VOCAB_GRAMMAR_BLOGS_TRENDING: '/ielts-blogs/vocab-grammar/trending',
    GET_TRENDING_HOME: '/trending/home',
    GET_NEWLY_UPDATED_TESTS: '/full-test/landing-newly-updated',
    GET_STATISTICS_BY_BOOK_ID: '/statistics/do-test?type=BOOK&referenceId=:id',
    GET_PRACTICE_SAMPLES: '/samples/list',
    GET_LATEST_NEWS_LIST: '/daily-learning/news/latest',
    GET_POPULAR_NEWS_LIST: '/daily-learning/news/popular',
    GET_RECENT_NEWS_LIST: '/daily-learning/news/recent',
    SUBMIT_NEW_USER_REGISTRATION: '/user-registration/new',
    UPDATE_USER_INTERACTION: '/page/user-interactions',
    GET_EXAM_QUESTIONS_PREDICTION: '/samples/exam-questions-predictions/get',
    GET_BOOK_LANDING_BY_ID: '/books/landing/:id',
    GET_PAGE_CLAP: '/page/:id/clap',
    CLAP_PAGE: '/page/:id/clap',
    GET_BOOKS_DETAIL: '/books/detail',
    GET_IE_BLOG_TRENDING: '/trending/ie-blogs-landing',
    GET_TRENDING_BOOKS: '/trending/books/overview',
  },
  searchTransform: {
    CLICK_TRACKING: '/search/click',
    DICTIONARY_SEARCH_RESULT: '/dictionary/result',
    SEARCH_RESULT: '/search/result',
    SEARCH_SUGGESTION: '/search/suggestion',
    GET_LANDING_FILTER_ELASTIC: '/filter',
    GET_LANDING_SUGGESTION_ELASTIC: '/filter/suggestion',
    POPULAR_RESULT: '/search/popular-result',
    GET_DICTIONARY_SUGGEST: '/dictionary/suggestion',
    FILTER_SAMPLE: '/filter/samples',
    FILTER_IELTS_BLOGS: '/filter/blogs',
    FILTER_VOCABS: '/filter/vocabs',
  },
  membership: {
    REFERRALS_VALIDATE: '/dp-earnings/referral/validate',
  },
  tts: {
    TTS_PHONETIC: '/generate-with-download-url',
  },
  k12: {
    K12_EXAMS_AVAILABLE_YEARS: '/exams/available-years',
    K12_EXAMS_TRENDING: '/exams/trending',
    K12_EXAMS_LISTED: '/exams/listed',
    K12_EXAMS_LANDING: '/trending/landing',
  },
  user: {
    POST_EMAIL_CONFIRM: '/user-profile/email/confirm',
    GET_USER_HS_CONTACT: '/get-user-by-id-or-email',
  },
  midFinalTest: {
    GET_COURSE_SHEET_FINAL_TEST_DETAIL: '/course-sheet/final-test/:id',
    GET_SKILL_FINAL_TEST_ONLINE_STATUS: '/online/user-final-tests/:id/detail/status',
    GET_FINAL_TEST_INFO: '/user-final-tests/:id/info',
    GET_ONLINE_OFFLINE_TEST_OVERVIEW: '/user-final-tests/:id/marking/overview',
    GET_ONLINE_WRITING_MARKING: '/online/user-final-tests/:id/marking/writing/:assignmentId',
    GET_ONLINE_LISTENING_MARKING: '/online/user-final-tests/:id/marking/listening/:assignmentId',
    GET_ONLINE_READING_MARKING: '/online/user-final-tests/:id/marking/reading/:assignmentId',
    GET_ONLINE_SPEAKING_MARKING: '/online/user-final-tests/:id/marking/speaking/:assignmentId',
    GET_ONLINE_OFFLINE_TEST_OVERALL_RESULT: '/user-final-tests/:id/marking/overall',
    GET_ONLINE_OFFLINE_MARKING_SKILL_RESOURCE: '/resources/:id/skills/:skillType',
    GET_FINAL_TEST_HISTORY: '/user-final-test-event/by-uft-id/:id',
    GET_ONLINE_GRAMMAR_MARKING: '/online/user-final-tests/:id/marking/grammar/:assignmentId',
    GET_ONLINE_VOCAB_MARKING: '/online/user-final-tests/:id/marking/vocab/:assignmentId',
    GET_OFFLINE_MARKING_SKILL: '/offline/user-final-tests/:id/marking/skills/:skillType',
    GET_SKILL_FINAL_TEST_ONLINE_PROGRESS_DETAIL: '/online/user-final-tests/:id/detail/progress',
    GET_SKILL_FINAL_TEST_OFFLINE_DETAIL: '/offline/user-final-tests/:id/detail/skills/:skillType',
    GET_SKILL_FINAL_TEST_ONLINE_PROGRESS: '/online/user-final-tests/:id/detail/progress',
    GET_SKILL_FINAL_TEST_ONLINE_DETAIL: '/online/user-final-tests/:id/detail/skills/:skillType',
    GET_FINAL_TEST_SKILL: '/online-final-tests/:userFinalTestId/preview/intro',
  },
  entranceTest: {
    GET_ONLINE_ENTRANCE_TEST_PROGRESS: '/student-do-test/:userEntranceTestId/progress',
    GET_ENTRANCE_TEST_SKILL: '/online-entrance-tests/:userEntranceTestId/preview/intro',
    GET_ENTRANCE_TEST_CONTACT: '/user-entrance-tests/:userEntranceTestId/contact',
  },
  cms: {
    BOOK_REVIEW_BY_ID: '/book-reviews/:id',
    CENTER_REVIEW_BY_ID: '/center-reviews/:id',
    COURSE_REVIEW_BY_ID: '/course-reviews/:id',
    TEACHER_REVIEW_BY_ID: '/teacher-reviews/:id',
  },
  satEntranceFinal: {
    GET_FULL_TEST_PROGRESS_DETAIL_BY_ID: '/test-progress/full-tests/:id/detail',
    GET_FULL_TEST_RESULT_BY_ID: '/test-result/full-test/:id/overview',
    GET_USER_SAT_ENTRANCE_TEST_OVERVIEW: '/sat-test/student-do-test/:userEntranceTestId/overview',
  },
};
