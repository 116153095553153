/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Auth,
  AuthProvider,
  UserCredential,
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
  FacebookAuthProvider,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  initializeAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithCustomToken,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from '@firebase/auth';
import { initializeApp } from '@firebase/app';

import jwtDecode from 'jwt-decode';

import { environment } from '../../environment/environment';
import { clearRedirectLogin, markRedirectLogin } from '../action/redirect';
import { AUTH0_LOGGED, DEFAULT_USER_INFO, LoginProvider } from '../constants';
import {
  AuthConfig,
  AuthManagerParams,
  AuthUserInfo,
  PopupLoginParams,
} from '../interface/auth.interface';
import { IFireBaseAuthManager } from '../interface/IFireBaseAuthManager.interface';
import { isBrowser, checkIsLocalHost } from '../utils';
import {
  bootstrapFirebaseRealTimeDB,
  FireBaseRealTimeDBManager,
} from '../../realtime-db/FireBaseRealTimeDBManager';

const defaultClaimInfo = {
  userMetadata: {},
  appMetadata: {},
  roles: {},
};

export class FireBaseAuthManager implements IFireBaseAuthManager {
  readonly localStorageKey: string;

  customCredential: UserCredential;

  authConfig: AuthConfig;

  readonly googleProvider: GoogleAuthProvider;

  readonly facebookProvider: FacebookAuthProvider;

  auth: Auth;

  realtimeDB: FireBaseRealTimeDBManager;

  readonly serverAuth: Auth;

  readonly primaryApp;

  constructor(params: AuthManagerParams) {
    this.localStorageKey = params.localStorageKey;
    this.authConfig = params.auth;
    if (isBrowser()) {
      this.primaryApp = initializeApp(
        {
          apiKey: params.auth.apiKey,
          authDomain: params.auth.authDomain,
          projectId: params.auth.projectId,
          databaseURL: params.auth.dataBaseUrl,
        },
        'primary'
      );
      initializeAuth(this.primaryApp, {
        persistence: indexedDBLocalPersistence,
      });
      this.auth = getAuth(this.primaryApp);
      if (this.authConfig.languageCode) {
        this.auth.languageCode = this.authConfig.languageCode;
      }
      this.googleProvider = new GoogleAuthProvider();
      this.googleProvider.addScope('email');
      this.facebookProvider = new FacebookAuthProvider();
      this.facebookProvider.setCustomParameters({
        display: 'popup',
      });
      this.realtimeDB = bootstrapFirebaseRealTimeDB({ app: this.primaryApp } as any);
    } else {
      const serverApp = initializeApp(
        {
          apiKey: params.auth.apiKey,
          authDomain: params.auth.authDomain,
          projectId: params.auth.projectId,
          databaseURL: params.auth.dataBaseUrl,
        },
        'server-app'
      );
      this.serverAuth = getAuth(serverApp);
    }
  }

  getInitApp() {
    if (isBrowser()) {
      return { app: this.primaryApp, auth: this.auth };
    }
    return { app: this.primaryApp, auth: this.serverAuth };
  }

  async updateAuthApp() {
    if (isBrowser() && !this.auth) {
      this.auth = getAuth();
    }
  }

  async signInCustomToken(token?: string): Promise<string> {
    if (!isBrowser()) {
      if (!this.customCredential || !this.customCredential.user) {
        this.customCredential = await signInWithCustomToken(this.serverAuth, token);
      }
      return this.customCredential.user.getIdToken();
    }
  }

  hasCustomToken(): boolean {
    if (!isBrowser()) {
      return Boolean(this.serverAuth.currentUser);
    }
    return false;
  }

  async getSignInCustomToken(): Promise<string> {
    if (!isBrowser()) {
      return this.serverAuth.currentUser?.getIdToken();
    }
  }

  async syncUserInfoToStorage(user = this.auth.currentUser) {
    window.localStorage.setItem(environment.REACT_AUTH_CURRENT_USER_INFO, JSON.stringify(user));
    let idToken = null;
    let customClaimInfo = defaultClaimInfo;
    if (user) {
      idToken = await user.getIdToken();
      const userDecode = this.decodeToken(idToken);
      customClaimInfo = {
        userMetadata: userDecode.userMetadata,
        appMetadata: userDecode.appMetadata,
        roles: userDecode.roles,
      };
      localStorage.setItem('TOKEN_EXPIRED_AT', userDecode.exp);
    }
    window.localStorage.setItem(environment.REACT_AUTH_CURRENT_USER_ID_TOKEN, idToken);
    window.localStorage.setItem(
      environment.REACT_AUTH_CURRENT_USER_CUSTOM_CLAIM,
      JSON.stringify(customClaimInfo)
    );
  }

  getExpiredTokenTimeStamps() {
    if (isBrowser()) {
      return Number(localStorage.getItem('TOKEN_EXPIRED_AT'));
    }
    return null;
  }

  getCurrentUserIdToken() {
    if (isBrowser()) {
      try {
        return window.localStorage.getItem(environment.REACT_AUTH_CURRENT_USER_ID_TOKEN);
        // eslint-disable-next-line no-empty
      } catch (ex) {}
    }
    return null;
  }

  getCurrentUser() {
    if (isBrowser()) {
      try {
        const userInfo = JSON.parse(
          window.localStorage.getItem(environment.REACT_AUTH_CURRENT_USER_INFO)
        );
        const fakeUserEmail = localStorage.getItem('X_USER_EMAIL');
        const fakeUserId = localStorage.getItem('X_USER_ID');
        return userInfo
          ? {
              ...userInfo,
              email: fakeUserEmail || userInfo?.email,
              id: fakeUserId || userInfo?.id,
              uid: fakeUserId || userInfo?.uid,
            }
          : null;
        // eslint-disable-next-line no-empty
      } catch (ex) {}
    }
    return null;
  }

  isLoggedIn(): boolean {
    if (isBrowser()) {
      const user = this.getCurrentUser();
      if (this.realtimeDB) {
        this.realtimeDB.forceRealtimeLogin();
      }
      return Boolean(user);
    }
    return false;
  }

  async saveUserInfo() {
    const user = await this.getUserInfo();
    window.localStorage.setItem(
      environment.REACT_AUTH_LOCAL_STORAGE_KEY,
      JSON.stringify({
        userInfo: user,
      })
    );
  }

  async getUserInfo(forceRefresh?: boolean): Promise<AuthUserInfo> {
    if (isBrowser() && this.isLoggedIn()) {
      const userInfo = this.getCurrentUser() as AuthUserInfo;
      await this.getAccessToken(forceRefresh);
      const customClaims = this.getCustomClaims();
      const { userMetadata } = customClaims;
      let email = userMetadata?.email || userInfo?.email;
      if (email == null && userInfo?.providerData?.length > 0) {
        email = userInfo.providerData.find((d) => d.email)?.email;
      }
      if (email == null) {
        email = '...';
      }
      const photoURL = userInfo?.providerData?.[0]?.photoURL || userInfo?.photoURL;

      return {
        ...userInfo,
        ...customClaims,
        email,
        photoURL,
        name: userInfo.displayName,
      } as AuthUserInfo;
    }
    return DEFAULT_USER_INFO;
  }

  async getUserRoles(): Promise<string[]> {
    const userInfo = await this.getUserInfo();
    return userInfo.roles;
  }

  async getAccessToken(forceRefresh?: boolean) {
    if (forceRefresh) {
      const token = this.auth.currentUser?.getIdToken(forceRefresh);
      await this.syncUserInfoToStorage();
      return token;
    }
    return this.getCurrentUserIdToken();
  }

  getAuthProvider(provider: LoginProvider): AuthProvider {
    let loginProvider;
    switch (provider) {
      case LoginProvider.GOOGLE: {
        loginProvider = this.googleProvider;
        break;
      }
      case LoginProvider.FACEBOOK: {
        loginProvider = this.facebookProvider;
        break;
      }
      default: {
        loginProvider = this.googleProvider;
        break;
      }
    }
    return loginProvider;
  }

  async handleRedirectResult() {
    if (isBrowser() && this.auth) {
      const result = await getRedirectResult(this.auth, browserPopupRedirectResolver);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      return { result, credential, token };
    }
  }

  async loginWithRedirect({ provider }) {
    markRedirectLogin();
    const data = await signInWithRedirect(
      this.auth,
      this.getAuthProvider(provider),
      browserPopupRedirectResolver
    );
    await this.syncUserInfoToStorage();
  }

  async handleAuthError(error) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData?.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);

    console.error({ credential, errorCode, errorMessage, email });
  }

  async popupLogin({ provider, afterLogin }: PopupLoginParams) {
    if (this.isLoggedIn()) {
      await this.logout();
    }
    const data = await signInWithPopup(
      this.auth,
      this.getAuthProvider(provider),
      browserPopupRedirectResolver
    );
    await this.syncUserInfoToStorage(data.user);
    afterLogin();
  }

  async login({ provider, afterLogin }) {
    if (isBrowser()) {
      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      const isLocalHost = await checkIsLocalHost();
      if (isFirefox || isLocalHost) {
        await this.popupLogin({
          provider,
          afterLogin,
        });
        return;
      }
      await this.loginWithRedirect({ provider });
    }
  }

  async logout(withRedirect?: boolean) {
    const isLoggedIn = this.isLoggedIn();
    if (isLoggedIn) {
      await signOut(this.auth);
      sessionStorage.setItem('isNewUser', 'false');
      clearRedirectLogin();

      window.localStorage.setItem(environment.REACT_AUTH_CURRENT_USER_INFO, null);
      window.localStorage.setItem(environment.REACT_AUTH_CURRENT_USER_ID_TOKEN, null);
      window.localStorage.setItem(
        environment.REACT_AUTH_CURRENT_USER_CUSTOM_CLAIM,
        JSON.stringify(defaultClaimInfo)
      );

      if (withRedirect) {
        window.location.href = this.authConfig.logOutRedirectUrl;
      }
    }
    window.localStorage.removeItem(environment.REACT_AUTH_PHONE_VERIFIED_LOCAL_STORAGE_KEY);
    window.localStorage.removeItem(environment.REACT_AUTH_LOCAL_STORAGE_KEY);
  }

  decodeToken(token): any {
    try {
      return jwtDecode(token);
    } catch (error) {
      throw new Error(`Failed to parse token with error: ${error.message}`);
    }
  }

  async handleCallback(): Promise<UserCredential | null> {
    if (isBrowser()) {
      return getRedirectResult(this.auth, browserPopupRedirectResolver);
    }
  }

  onAuthStateChanged(nextOrObserver) {
    if (isBrowser()) {
      return onAuthStateChanged(this.auth, nextOrObserver);
    }
  }

  onIdTokenChanged(nextOrObserver) {
    if (isBrowser()) {
      return onIdTokenChanged(this.auth, nextOrObserver);
    }
  }

  saveLocalPhoneVerified(verified: boolean) {
    window.localStorage.setItem(
      environment.REACT_AUTH_PHONE_VERIFIED_LOCAL_STORAGE_KEY,
      verified.toString()
    );
  }

  getCustomClaims() {
    if (isBrowser()) {
      try {
        return JSON.parse(
          window.localStorage.getItem(environment.REACT_AUTH_CURRENT_USER_CUSTOM_CLAIM)
        );
        // eslint-disable-next-line no-empty
      } catch (ex) {}
    }
    return defaultClaimInfo;
  }

  async needVerifyPhone() {
    if (typeof window !== 'undefined') {
      const userInfo = await this.getUserInfo();
      const phoneVerified = userInfo.userMetadata && Boolean(userInfo.userMetadata.phoneVerified);
      const localPhoneVerified = window.localStorage.getItem(
        environment.REACT_AUTH_PHONE_VERIFIED_LOCAL_STORAGE_KEY
      );
      return !(phoneVerified || localPhoneVerified);
    }
    return false;
  }

  async isCompleteSignUp() {
    return this.isLoggedIn() && !(await this.needVerifyPhone());
  }

  async isWaitingForVerifyingPhone() {
    return (await this.isLoggedIn()) && (await this.needVerifyPhone());
  }

  async isBanned(): Promise<boolean> {
    const userInfo = await this.getUserInfo();
    return userInfo && userInfo.userMetadata && userInfo.userMetadata.banned;
  }

  syncIdTokenInfo(idToken) {
    let customClaimInfo = defaultClaimInfo;
    const userDecode = this.decodeToken(idToken);
    customClaimInfo = {
      userMetadata: userDecode.userMetadata,
      appMetadata: userDecode.appMetadata,
      roles: userDecode.roles,
    };
    window.localStorage.setItem(environment.REACT_AUTH_CURRENT_USER_ID_TOKEN, idToken);
    window.localStorage.setItem(
      environment.REACT_AUTH_CURRENT_USER_CUSTOM_CLAIM,
      JSON.stringify(customClaimInfo)
    );
  }

  async refreshToken() {
    if (this?.auth?.currentUser) {
      const idToken = await this.auth.currentUser.getIdToken(true);
      this.syncIdTokenInfo(idToken);
    }
  }

  async updateIdToken() {
    if (this?.auth?.currentUser) {
      const idToken = await this.auth.currentUser.getIdToken(false);
      this.syncIdTokenInfo(idToken);
    }
  }

  saveHaveLoggedIn() {
    localStorage.setItem(AUTH0_LOGGED, 'true');
  }

  clearAuthData() {
    localStorage.removeItem(AUTH0_LOGGED);
  }

  getCurrentLocalStorageSpell() {
    return JSON.stringify(window.localStorage);
  }

  async loginByLocalStorageString(localStorageString: string) {
    const lStorage = JSON.parse(localStorageString);
    Object.entries(lStorage).forEach(([key, value]) => {
      window.localStorage[key] = value;
    });
  }

  async haveLoggedIn() {
    if (typeof window !== 'undefined') {
      return JSON.parse(localStorage.getItem(AUTH0_LOGGED));
    }
  }

  async isPurchasesApp(app) {
    const userInfo = await this.getUserInfo();
    if (userInfo) {
      return (
        userInfo.appMetadata &&
        userInfo.appMetadata[app.toLowerCase()]?.subscriptionStatus === 'PURCHASES'
      );
    }
    return false;
  }

  userForAccountLinking = async (provider: LoginProvider) => {
    const secondaryApp = initializeApp(
      {
        apiKey: this.authConfig.apiKey,
        authDomain: this.authConfig.authDomain,
      },
      'secondary'
    );
    const secondaryAuth = getAuth(secondaryApp);
    const newUser = await signInWithPopup(secondaryAuth, this.getAuthProvider(provider));
    const userInfo = newUser.user.toJSON() as AuthUserInfo;
    const userDecode = this.decodeToken(await secondaryAuth.currentUser?.getIdToken());
    return {
      ...userInfo,
      userMetadata: userDecode.userMetadata,
    } as AuthUserInfo;
  };
}
